import React from "react"
import SEO from "../components/seo"
import { motion } from 'framer-motion'

const duration = 0.35

const container = {
  visible: {
    transition: {
      when: 'beforeChildren',
      staggerChildren: 0.2,
      delayChildren: duration,
    },
  },
}
const item = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
}

const AboutPage = () => {
  return (
    <>
      <SEO title="About" />
      <motion.section
        variants={container}
        initial="hidden" 
        animate="visible"
        className="container"
      >
        <motion.div 
          className="content"
          variants={item}
          transition="easeInOut"
        >
          <p className="text-lg md:text-xl pl-3">About</p>
        </motion.div>
        <motion.div 
          className="content pl-5"
          variants={item}
          transition="easeInOut"
        >
          <p>Chicago Asian Writers Workshop (CAWW) is a creative writing workshop for Asian diasporic writers and writers of color based in Chicago and the Midwest. It was founded in summer 2020 by writer and bookseller A. Ng Pavel,  thanks to a generous grant from The University of Chicago. Its mission is to uplift and contextualize Asian voices in America, and to practice radical inclusivity by providing a literary and artistic space to nurture emerging writers of color. It is inspired by the Asian American Writers Workshop (AAWW) in New York City, founded in 1991. CAWW aims to make heard literature of migration, race, social justice, and intersectional feminism, and most importantly, to found a community of writers of color in Chicago, and more broadly, in the Midwest. </p>

          <p>In summer 2020, CAWW hosted two workshops, in fiction and in poetry for young writers of Asian descent between the ages of 18 and 35. A lit mag compiling some of the works workshopped can be viewed here.</p>
          
          <p>The project is continuing into Summer 2021 with generous support from the Stories Matter Foundation of Chicago.</p>
        </motion.div>
      </motion.section>

      
    </>
  )
}

export default AboutPage